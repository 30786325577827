import { Resource } from 'idea-toolbox';

/**
 * One of the entities that a team can use to group users and activities.
 */
export class Group extends Resource {
  /**
   * The ID of the team containing the group.
   */
  teamId: string;
  /**
   * The ID of the group.
   */
  groupId: string;
  /**
   * The name of the group.
   */
  name: string;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.groupId = this.clean(x.groupId, String);
    this.name = this.clean(x.name, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.groupId = safeData.groupId;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.groupId)) e.push('groupId');
    if (this.iE(this.name)) e.push('name');
    return e;
  }
}

/**
 * The different permissions a user can have on a specific group.
 */
export enum GroupPermissions {
  NONE = 0,
  BELONG = 10,
  READ = 20,
  WRITE = 30
}
