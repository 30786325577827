/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'orazio',
    ionicExtraModules: ['common', 'variables', 'auth', 'teams'],
    app: {
      version: '3.20.1',
      url: 'https://horace.cloud'
    },
    api: {
      url: 'api.horace.cloud',
      stage: 'prod'
    },
    ideaApi: {
      url: 'api.iter-idea.com',
      stage: 'prod'
    },
    auth: {
      title: 'Horace',
      website: 'https://iter-idea.com',
      hasIntroPage: true,
      registrationIsPossible: false,
      singleSimultaneousSession: false,
      forceLoginWithMFA: false,
      passwordPolicy: {
        minLength: 8,
        requireLowercase: false,
        requireDigits: false,
        requireSymbols: false,
        requireUppercase: false
      }
    },
    scarlett: {
      apiURL: 'api-scarlett.iter-idea.com',
      apiStage: 'prod',
      appURL: 'scarlett.iter-idea.com'
    }
  },
  aws: {
    cognito: {
      userPoolId: 'eu-west-2_vbuZa7Ydg',
      userPoolClientId: '5k5e7unsb8q5f2j3ipk6d0mrqq'
    }
  },
  github: {
    clientId: 'c154f8885728543a867b'
  },
  trello: {
    apiKey: '98566847ee394595badd03c082e3ee89'
  },
  jira: {
    clientId: 'nXLcAoP1lxUyQNINQLAzAac0kcXI0LRX',
    callbackURL: 'https://api.horace.cloud/prod/jira/callback',
    scope: 'read:jira-work offline_access'
  }
};
