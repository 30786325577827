import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { initGuard } from './init.guard';
import { authGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'teams',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('@idea-ionic/auth').then(m => m.IDEAAuthModule),
    canActivate: [initGuard]
  },
  {
    path: 'echo',
    loadChildren: () => import('@idea-ionic/common').then(m => m.IDEAEchoModule),
    canActivate: [initGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule),
    canActivate: [initGuard]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./tutorial/tutorial.module').then(m => m.TutorialModule),
    canActivate: [initGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('@idea-ionic/teams').then(m => m.IDEAAccountModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams',
    loadChildren: () => import('@idea-ionic/teams').then(m => m.IDEATeamsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId',
    loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
    canActivate: [initGuard, authGuard]
  },
  {
    // DO NOT CHANGE: standard IDEA; leave at least an alias for this path
    path: 'teams/:teamId/settings',
    redirectTo: 'teams/:teamId'
  },
  {
    path: 'teams/:teamId/dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/badge',
    loadChildren: () => import('./badge/badge.module').then(m => m.BadgeModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/activities/:activityId/tracks/:trackId',
    loadChildren: () => import('./tracks/track.module').then(m => m.TrackModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/tracks',
    loadChildren: () => import('./tracks/tracks.module').then(m => m.TracksModule),
    canActivate: [initGuard, authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
