import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Announcement } from 'idea-toolbox';
import { NavController, Platform } from '@ionic/angular';
import { CacheModes, IDEAAWSAPIService, IDEATinCanService, IDEATranslationsService } from '@idea-ionic/common';

import { AppService } from './app.service';

import { ServiceLanguages } from '@models/serviceLanguages.enum';

import { environment as env } from '@env';

export const initGuard: CanActivateFn = async (): Promise<boolean> => {
  const platform = inject(Platform);
  const t = inject(IDEATranslationsService);
  const app = inject(AppService);
  const api = inject(IDEAAWSAPIService);
  const tc = inject(IDEATinCanService);
  const navCtrl = inject(NavController);

  /**
   * HELPERS
   */
  const setUpAndroidBackButton = (): void => {
    // implement Android physical back button (low priority so that other Ionic's features come first)
    platform.backButton.subscribeWithPriority(0, (): void => {
      // determ the homepage, based on the fact we are logged in or not
      const homepage = tc.get('membership') ? `/teams/${String(tc.get('membership').teamId)}` : '/auth/sign-in';
      // try to go back; note: the location is updated only to the previuous "back" performed
      window.history.back();
      // if while going back we go further the homepage, exit the app to please Veronica
      if (window.location.pathname === homepage && navigator && (navigator as any)['app'])
        (navigator as any)['app'].exitApp();
    });
  };
  const interceptTrelloToken = (): boolean => {
    const token = window?.location?.hash.startsWith('#token=') ? window.location.hash.slice(7) : null;
    if (token) window.location.assign(`${env.idea.app.url}/echo?request=trello-integration&code=${token}`);
    return Boolean(token);
  };
  const checkForAnnouncementsOrSetMaintenanceMode = async (): Promise<void> => {
    const announcement: Announcement = await api.getResource('announcements', {
      idea: true,
      resourceId: env.idea.project,
      useCache: CacheModes.NETWORK_FIRST
    });

    if (announcement?.content) {
      tc.set('idea-announcement', new Announcement(announcement));
      // if the announcment is of maintenance, navigate to Echo to display it
      if (announcement.maintenance) navCtrl.navigateRoot(['echo', 'maintenance']);
    }
  };

  /**
   * MAIN
   */

  if (app.initReady) return true;

  await platform.ready();

  await t.init(Object.values(ServiceLanguages), ServiceLanguages.Italian);

  if (interceptTrelloToken()) return;

  await checkForAnnouncementsOrSetMaintenanceMode();

  setUpAndroidBackButton();

  app.initReady = true;
  return true;
};
