import { Resource } from 'idea-toolbox';

/**
 * Table: `orazio_teamsUsers_tags`.
 */
export class Tag extends Resource {
  /**
   * Concatenation of teamId and userId.
   */
  teamUserId: string;
  /**
   * The tag (lowercase, only letters and digits).
   */
  tag: string;
  /**
   * How many times the tag has been used by the user in the team.
   */
  counter: number;

  static clean(tag: string): string {
    return String(tag)
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '');
  }

  load(x: any): void {
    super.load(x);
    this.teamUserId = this.clean(x.teamUserId, String);
    this.tag = this.clean(x.tag, t => Tag.clean(t));
    this.counter = this.clean(x.counter, Number, 0);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamUserId = safeData.teamUserId;
    this.tag = safeData.tag;
    this.counter = safeData.counter;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.tag)) e.push('tag');
    return e;
  }
}
