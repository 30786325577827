import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Params } from '@angular/router';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { User, Team as IDEATeam } from 'idea-toolbox';
import { IDEATinCanService, IDEAAWSAPIService, IDEATranslationsService } from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { AppService } from './app.service';

import { environment as env } from '@env';
import { Membership } from '@models/membership.model';
import { Team } from '@models/team.model';
import { Group } from '@models/group.model';

export const authGuard: CanActivateFn = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
  const platform = inject(Platform);
  const navCtrl = inject(NavController);
  const auth = inject(IDEAAuthService);
  const app = inject(AppService);
  const tc = inject(IDEATinCanService);
  const alertCtrl = inject(AlertController);
  const t = inject(IDEATranslationsService);
  const api = inject(IDEAAWSAPIService);

  if (app.authReady) return true;

  //
  // HELPERS
  //

  const showAlertRobotCantSignIn = async (): Promise<void> => {
    const header = t._('IDEA_AUTH.ROBOT_USERS_CANT_SIGN_INTO_APPS');
    const message = t._('IDEA_AUTH.ROBOT_USERS_CANT_SIGN_INTO_APPS_I');
    const buttons = [t._('COMMON.GOT_IT')];

    const alert = await alertCtrl.create({ header, message, buttons });
    alert.present();
  };

  const redoAuthIfInvalidToken = async (): Promise<void> => {
    try {
      await api.getResource('users', {
        idea: true,
        resourceId: tc.get('userId'),
        params: { project: env.idea.project }
      });
    } catch (error) {
      // token expired (probably); re-do the auth
      const res = await auth.isAuthenticated(false, freshIdToken => tc.set('AWSAPIAuthToken', freshIdToken));
      tc.set('AWSAPIAuthToken', res.idToken);
    }
  };
  const navigateAndResolve = (navigationPath?: any[], queryParams?: Params): boolean => {
    if (navigationPath) navCtrl.navigateRoot(navigationPath, { queryParams });
    app.authReady = true;
    return true;
  };
  const continueNavigation = (): boolean => {
    if (tc.get('membership')?.showTutorial) return navigateAndResolve(['tutorial']);
    else return navigateAndResolve();
  };

  const getTeamAndMembershipAndResolve = async (teamId: string): Promise<boolean> => {
    try {
      const userTeamMembership = await api.getResource(`teams/${teamId}/memberships`, {
        resourceId: tc.get('userId'),
        params: { startUp: true }
      });

      const team = new Team(userTeamMembership['team']);
      tc.set('team', team);

      const groups = (userTeamMembership['groups'] ?? []).map((g: Group): Group => new Group(g));
      tc.set('groups', groups);

      t.setLangs(team.languages.available);
      t.setDefaultLang(team.languages.default);
      if (!team.languages.available.some(x => x === t.getCurrentLang())) t.use(team.languages.default);

      const membership = new Membership(userTeamMembership['membership']);
      tc.set('membership', membership);

      // (async) load helper resources; slow down the req (lowest priority compared to main page's ones)
      setTimeout((): Promise<void> => loadSupportResources(), 2000);

      // if the user has the badge mode enabled, we go the the badge page.
      if (membership.permissions.useBadgeMode && !tc.get('actAsUser'))
        return navigateAndResolve(['teams', team.teamId, 'badge'], route.queryParams);

      // if the user tries to go the badge page but he hasn't the permissions, we go the the dashboard.
      if (window.location.pathname.includes('badge') && !membership.canUseBadgeMode())
        return navigateAndResolve(['teams', teamId, 'dashboard']);

      // if we opened the app from a deep link, the url is already set (don't force it to '/')
      if (!tc.get('fromDeepLink') && window.location.pathname === '/')
        return navigateAndResolve(['teams', teamId, 'dashboard']);
      else return continueNavigation();
    } catch (error) {
      navigateAndResolve(['intro']);
    }
  };

  const loadSupportResources = async (): Promise<void> => {
    try {
      const [memberships, teams]: [Membership[], IDEATeam[]] = await Promise.all([
        api.getResource(`teams/${tc.get('membership').teamId}/memberships`),
        api.getResource('teams', { idea: true, params: { project: env.idea.project } })
      ]);
      tc.set('memberships', memberships);
      const tp = teams.filter(t => t.activeInProjects.includes(env.idea.project)).map(t => new IDEATeam(t));
      tc.set('teams', tp);
    } catch (ignoreErrors) {
      //
    }
  };

  //
  // MAIN
  //

  if (app.authReady) return true;

  await platform.ready();

  try {
    const result = await auth.isAuthenticated(false, freshIdToken => tc.set('AWSAPIAuthToken', freshIdToken));
    tc.set('AWSAPIAuthToken', result.idToken);
    tc.set('userId', result.userDetails.sub);

    // when the app is resumed from idle, check the token to see if it's expired; in case, re-do the auth
    platform.resume.subscribe((): Promise<void> => redoAuthIfInvalidToken());

    const user = new User(
      await api.getResource('users', { idea: true, resourceId: tc.get('userId'), params: { project: env } })
    );
    tc.set('user', user);

    // get the team to consider (from the URL or from the user's preferences)
    const teamId = route.paramMap.get('teamId') ?? user.getCurrentTeamOfProject(env.idea.project);
    if (!teamId) return navigateAndResolve(['teams']);
    // load the user's team and membership (taking advantage of startUp mode) and resolve
    else return getTeamAndMembershipAndResolve(teamId);
  } catch (error) {
    switch (error?.message) {
      case 'ROBOT_USER':
        await showAlertRobotCantSignIn();
        return navigateAndResolve(['intro']);
      default:
        return navigateAndResolve(['intro']);
    }
  }
};
