import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');

import {
  IDEAEnvironment,
  IDEACustomFieldsModule,
  IDEASelectModule,
  IDEACheckerModule,
  IDEATranslationsModule,
  IDEAAnnouncementModule,
  IDEAActionSheetModule,
  IDEAAWSAPIService,
  IDEAErrorReportingService,
  IDEAOfflineService
} from '@idea-ionic/common';
import { environment } from '@env';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    IonicStorageModule.forRoot({ name: 'orazio' }),
    HttpClientModule,
    IDEATranslationsModule,
    IDEAAnnouncementModule,
    IDEACustomFieldsModule,
    IDEASelectModule,
    IDEACheckerModule,
    IDEAActionSheetModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: IDEAEnvironment, useValue: environment },
    IDEAAWSAPIService,
    IDEAErrorReportingService,
    IDEAOfflineService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
