import { NotificationPreferences as NP, EventNotificationPreference, Resource, epochISOString } from 'idea-toolbox';

import { Group, GroupPermissions } from './group.model';

/**
 * Table: `orazio_teams_users`.
 *
 * Indexes:
 *    - `teamId-name-index-2`.
 *    - `userId-index`.
 */
export class Membership extends Resource {
  /**
   * The id of the team.
   */
  teamId: string;
  /**
   * The id of the user.
   */
  userId: string;
  /**
   * The name of the user (copy from User).
   */
  name: string;
  /**
   * The permissions of the user on the team.
   */
  permissions: TeamPermissions;
  /**
   * The user's notification preferences in the team.
   */
  notificationPreferences: NotificationPreferences;
  /**
   * If the attribute exists, the user still need to go through the tutorial.
   */
  showTutorial?: boolean;
  /**
   * The list of groups (IDs) that this membership is part of and repective permissions.
   */
  groups: { [groupId: string]: GroupPermissions };
  /**
   * Array from 0 (Mon) to 6 (Sun). For each day, the target of hours that the user should reach (total duration).
   * If the target for a day is set to `0`, the day doesn't have a target.
   * If not set, fallaback to the team's default settings.
   * e.g. if the index `2` of the array is `0`, Wednesday won't have a target.
   * e.g. if the index `0` of the array is `8`, Monday's daily target is set to 8 hours.
   */
  weekDaysTarget?: number[];
  /**
   * Date when the cache was last updated.
   */
  cacheUpdatedAt: epochISOString;
  /**
   * The badge ID used when badge mode is enabled for this user.
   */
  badgeId?: string;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.userId = this.clean(x.userId, String);
    this.name = this.clean(x.name, String);
    this.permissions = new TeamPermissions(x.permissions);
    this.permissions.useBadgeMode = this.permissions.useBadgeMode && this.canUseBadgeMode();
    this.notificationPreferences = new NotificationPreferences(x.notificationPreferences);
    if (x.showTutorial) this.showTutorial = this.clean(x.showTutorial, Boolean);
    this.groups = {};
    if (x.groups) {
      for (const groupId in x.groups) {
        if (x.groups[groupId]) this.groups[groupId] = x.groups[groupId];
      }
    }
    if (x.weekDaysTarget) {
      this.weekDaysTarget = [];
      for (let i = 0; i < 7; i++) this.weekDaysTarget.push(x.weekDaysTarget ? x.weekDaysTarget[i] ?? 0 : 0);
    }
    this.cacheUpdatedAt = this.clean(x.cacheUpdatedAt, d => new Date(d).toISOString());
    if (x.badgeId) this.badgeId = this.clean(x.badgeId, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.userId = safeData.userId;
    this.permissions = safeData.permissions;
    if (safeData.showTutorial) this.showTutorial = safeData.showTutorial;
    this.groups = safeData.groups;
  }

  /**
   * Whether the current membership is part of the specified group.
   */
  isPartOfGroup(group: Group | string): boolean {
    return this.getGroupPermission(group) >= GroupPermissions.BELONG;
  }
  /**
   * Get the user's current permission for the desired group.
   */
  getGroupPermission(group: Group | string): GroupPermissions {
    const groupId = typeof group === 'string' ? group : group.groupId;
    return this.groups?.[groupId] ?? GroupPermissions.NONE;
  }
  /**
   * Set the user's current permission for the desired group.
   */
  setGroupPermission(group: Group | string, permission: GroupPermissions): void {
    const groupId = typeof group === 'string' ? group : group.groupId;
    if (!groupId) return;
    if (!this.groups) this.groups = {};
    if (permission) this.groups[groupId] = permission;
    else delete this.groups[groupId];
  }
  /**
   * Check whether the user has read permission for the desired group.
   */
  canReadGroup(group: Group | string): boolean {
    return this.getGroupPermission(group) >= GroupPermissions.READ;
  }
  /**
   * Check whether the user has write permission for the desired group.
   */
  canWriteGroup(group: Group | string): boolean {
    return this.getGroupPermission(group) >= GroupPermissions.WRITE;
  }
  /**
   * Check whether the user can use the badge mode.
   */
  canUseBadgeMode(permissions?: TeamPermissions): boolean {
    permissions = permissions ?? this.permissions;
    return !permissions.canSeeTracks && !permissions.canManageTracks && !permissions.canManageTeam;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.weekDaysTarget && this.weekDaysTarget.some(x => x < 0 || x >= 24)) e.push('weekDaysTarget');
    return e;
  }
}

export class TeamPermissions extends Resource {
  [key: string]: boolean | any;
  /**
   * Can manage team details and team members.
   */
  canManageTeam: boolean;
  /**
   * Can see the tracks of other users and their details.
   */
  canSeeTracks: boolean;
  /**
   * Can manage tracks of other users.
   */
  canManageTracks: boolean;
  /**
   * Whether this account is used in badge mode (restricted mode where each user, when logged in, sees only his resources).
   */
  useBadgeMode: boolean;

  load(x: any): void {
    super.load(x);
    this.canManageTeam = this.clean(x.canManageTeam, Boolean);
    this.canSeeTracks = this.clean(x.canSeeTracks, Boolean);
    this.canManageTracks = this.clean(x.canManageTracks, Boolean);
    this.useBadgeMode = this.clean(x.useBadgeMode, Boolean);
  }
}

/**
 * A brief representation of a Membership, to attach to other entities.
 */
export class MembershipSummary extends Resource {
  userId: string;
  name: string;

  load(x: any): void {
    super.load(x);
    this.userId = this.clean(x.userId, String);
    this.name = this.clean(x.name, String);
  }
}

/**
 * The notification preferences available for a membership.
 */
export class NotificationPreferences implements NP {
  /**
   * The map of events with their notification preferences.
   */
  [event: string]: EventNotificationPreference;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(_: any = {}) {}
}
