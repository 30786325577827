import { CustomSectionMeta, Label, Languages, Resource } from 'idea-toolbox';

import { Tag } from './tag.model';
import { ServiceLanguages } from './serviceLanguages.enum';

/**
 * Table: `orazio_teams`.
 */
export class Team extends Resource {
  /**
   * The id of the team
   */
  teamId: string;
  /**
   * The team name.
   */
  name: string;
  /**
   * Languages preferences for the team.
   */
  languages: Languages;
  /**
   * Business name, address and other information on the team.
   */
  info: Label;
  /**
   * Tags (lowercase, only letters and digits) to suggest to team members while they manage tracks.
   */
  tags: string[];
  /**
   * Array from 0 (Mon) to 6 (Sun). For each day, the target of hours that a user should reach (total duration).
   * If the target for a day is set to `0`, the day doesn't have a target.
   * e.g. if the index `2` of the array is `0`, Wednesday won't have a target.
   * e.g. if the index `0` of the array is `8`, Monday's daily target is set to 8 hours.
   */
  weekDaysTarget: number[];
  /**
   * The meta description of a section of custom fields, to be used in the tracks.
   */
  custom: CustomSectionMeta;
  /**
   * The dictionary of the team, to give a meaning to Horace's main objects.
   */
  dictionary: TeamDictionary;
  /**
   * The user id of the group owner.
   */
  ownerId: string;
  /**
   * Fixed fields options.
   */
  options: TeamOptions;
  /**
   * Fixed PDF fields options.
   */
  pdfOptions: PDFOptions;
  /**
   * Whether the integration with AIRP is active (back-end only flag).
   */
  airp?: boolean;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.name = this.clean(x.name, String);
    this.languages = new Languages(x.languages);
    this.info = new Label(x.info, this.languages);
    this.tags = Array.from(new Set(this.cleanArray(x.tags, t => Tag.clean(t))));
    this.weekDaysTarget = [];
    for (let i = 0; i < 7; i++) this.weekDaysTarget.push(x.weekDaysTarget ? x.weekDaysTarget[i] ?? 0 : 0);
    this.custom = new CustomSectionMeta(x.custom, this.languages);
    this.dictionary = new TeamDictionary(x.dictionary, this.languages);
    this.ownerId = this.clean(x.ownerId, String);
    this.options = new TeamOptions(x.options);
    this.pdfOptions = new PDFOptions(x.pdfOptions, this.languages);
    if (x.airp) this.airp = true;
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.ownerId = safeData.ownerId;
    if (safeData.airp) this.airp = true;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.name)) e.push('name');
    this.languages.validate(ServiceLanguages).forEach(ea => e.push(`languages.${ea}`));
    if (this.weekDaysTarget.some(x => x < 0 || x >= 24)) e.push('weekDaysTarget');
    if (this.options.refreshUserCacheEveryNumDays < 0) e.push('options.refreshUserCacheEveryNumDays');
    return e;
  }
}

export class TeamDictionary extends Resource {
  /**
   * The name of the category1 for the team.
   */
  category1: Label;
  /**
   * The name of the category1 for the team.
   */
  category2: Label;
  /**
   * The name of the activity for the team.
   */
  activity: Label;

  load(x: any, languages: Languages): void {
    super.load(x);
    this.category1 = new Label(x.category1, languages);
    this.category2 = new Label(x.category2, languages);
    this.activity = new Label(x.activity, languages);
  }
}

export class TeamOptions extends Resource {
  /**
   * If false, the team hides the first category in all the sections (but the value is preserved).
   */
  useCategory1: boolean;
  /**
   * If false, the team hides the second category in all the sections (but the value is preserved).
   */
  useCategory2: boolean;
  /**
   * If true, show the week selector in the dashboard's UI.
   */
  showWeekSelector: boolean;
  /**
   * Whether to show or not the filters on the categories (e.g. in the Dashboard).
   */
  showCategoriesFilters: boolean;
  /**
   * Show the billed hours fields while filing the track.
   */
  useBilledHours: boolean;
  /**
   * Billed Hours Calculation; if `null`: no calculation.
   */
  billedHoursCalculation: BilledHoursCalculation;
  /**
   * Show the distance expressed in km while filing the track.
   */
  useTravellingDistance: boolean;
  /**
   * The list of vehicles available to the team.
   */
  vehicles: string[];
  /**
   * The ID (cloudid) of the JIRA site to use to enable the integration with Atlassian JIRA.
   */
  useJIRASite: string;
  /**
   * Forbids a user to have more than one activity being recorded at a time.
   */
  onlyOneSimultaneousRecordingPerUser: boolean;
  /**
   * The time interval in days before the cache is refreshed auomatically. If 0, it never happens.
   */
  refreshUserCacheEveryNumDays: number;

  load(x: any): void {
    super.load(x);
    this.useCategory1 = this.clean(x.useCategory1, Boolean, true);
    this.useCategory2 = this.clean(x.useCategory2, Boolean, true);
    this.showWeekSelector = this.clean(x.showWeekSelector, Boolean, true);
    this.showCategoriesFilters = this.clean(x.showCategoriesFilters, Boolean);
    this.useBilledHours = this.clean(x.useBilledHours, Boolean);
    this.billedHoursCalculation = this.clean(x.billedHoursCalculation, Number, 0);
    this.useTravellingDistance = this.clean(x.useTravellingDistance, Boolean);
    this.vehicles = this.cleanArray(x.vehicles, String);
    if (x.useJIRASite) this.useJIRASite = this.clean(x.useJIRASite, String);
    this.onlyOneSimultaneousRecordingPerUser = this.clean(x.onlyOneSimultaneousRecordingPerUser, Boolean, false);
    this.refreshUserCacheEveryNumDays = this.clean(x.refreshUserCacheEveryNumDays, Number, 0);
  }
}

export class PDFOptions extends Resource {
  /**
   * If true, the report use a portrait orientation; landscape otherwise.
   */
  isPortrait: boolean;
  /**
   * If true, the report will switch to a new page when Category1 changes.
   */
  pageBreakOnCategory1: boolean;
  /**
   * If true, the report PDF hides the description of the track.
   */
  hideDescription: boolean;
  /**
   * If true, the report PDF hides the tags of the track.
   */
  hideTags: boolean;
  /**
   * Info to include into the header of the report. Markdown support.
   */
  headerInfo: Label;
  /**
   * Info to include into the footer of the report. Markdown support.
   */
  footerInfo: Label;

  load(x: any, languages: Languages): void {
    super.load(x);
    this.isPortrait = this.clean(x.isPortrait, Boolean, true);
    this.pageBreakOnCategory1 = this.clean(x.pageBreakOnCategory1, Boolean);
    this.hideDescription = this.clean(x.hideDescription, Boolean);
    this.hideTags = this.clean(x.hideTags, Boolean);
    this.headerInfo = new Label(x.headerInfo, languages);
    this.footerInfo = new Label(x.footerInfo, languages);
  }
}

export enum BilledHoursCalculation {
  NONE,
  FLOOR,
  NEAREST,
  CEIL
}

export class TeamSummary extends Resource {
  /**
   * The id of the team.
   */
  teamId: string;
  /**
   * The name of the team.
   */
  name: string;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.name = this.clean(x.name, String);
  }
}
