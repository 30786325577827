<ion-app>
  <ion-split-pane contentId="main" when="md" [disabled]="isPageWithoutMenu()">
    <ion-menu id="theMenu" contentId="main">
      <!-- USER MENU -->
      <ion-header class="ion-no-border" *ngIf="tc.get('membership') && tc.get('team')">
        <ion-toolbar [color]="app.isInDarkMode() ? 'transparent' : 'ideaToolbar'">
          <ion-avatar class="tappable profileAvatar" [title]="t._('MENU.PROFILE')" (click)="editProfile()">
            <ion-img [src]="app.getUserAvatar()" (ionError)="app.fallbackUserAvatar($event?.target)"></ion-img>
          </ion-avatar>
          <div class="underAvatar">
            <ion-text class="tappable" [title]="t._('MENU.PROFILE')" (click)="editProfile()">
              {{ tc.get('membership')?.name }}
            </ion-text>
            <ion-row class="ion-justify-content-center">
              <ion-col class="ion-text-center" *ngIf="!isInBadgeMode()" [size]="2">
                <ion-button fill="clear" color="medium" size="small" [title]="t._('MENU.DASHBOARD')" (click)="home()">
                  <ion-icon name="home" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col class="ion-text-center" *ngIf="!isInBadgeMode()" [size]="2">
                <ion-button
                  fill="clear"
                  color="medium"
                  size="small"
                  [title]="t._('MENU.SEARCH_TRACKS_HINT')"
                  (click)="tracks()"
                >
                  <ion-icon name="search" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col class="ion-text-center" [size]="2">
                <ion-button
                  fill="clear"
                  color="medium"
                  size="small"
                  [title]="t._('MENU.TUTORIAL')"
                  (click)="tutorial()"
                >
                  <ion-icon name="help" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col class="ion-text-center" [size]="2">
                <ion-button fill="clear" color="medium" size="small" [title]="t._('MENU.INFO')" (click)="app.info()">
                  <ion-icon name="information-circle" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col class="ion-text-center" [size]="2">
                <ion-button
                  fill="clear"
                  color="medium"
                  size="small"
                  *ngIf="canLogOut()"
                  [title]="t._('COMMON.LOGOUT')"
                  (click)="app.logout()"
                >
                  <ion-icon name="exit" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <!-- QUICK SWITCH USER -->
        <ion-list
          lines="none"
          class="quickSwitch"
          *ngIf="tc.get('memberships')?.length > 1 && app.userHasPermission('canManageTracks')"
        >
          <ion-list-header>
            <ion-label color="primary">{{ 'MENU.QUICK_SWITCH' | translate }}</ion-label>
            <ion-button size="small" (click)="hideQuickSwitch = !hideQuickSwitch">
              <ion-icon [name]="hideQuickSwitch ? 'eye' : 'eye-off'" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-list-header>
          <ion-item
            class="tappable"
            *ngIf="tc.get('actAsUser')"
            [title]="t._('DASHBOARD.TAP_TO_GO_BACK_TO_YOUR_TRACKS')"
            (click)="actAsUser()"
          >
            <ion-icon name="log-out" slot="start"></ion-icon>
            <b>{{ 'MENU.BACK_TO_MY_USER' | translate }}</b>
          </ion-item>
          <ng-container *ngIf="!hideQuickSwitch">
            <div *ngFor="let membership of tc.get('memberships')">
              <ion-item
                class="tappable"
                *ngIf="membership.userId !== tc.get('membership')?.userId"
                [title]="t._('MENU.SELECT_ALIAS_X', { user: membership.name })"
                [class.current]="tc.get('actAsUser')?.userId === membership.userId"
                (click)="actAsUser(membership)"
              >
                <ion-avatar slot="start">
                  <ion-img
                    [src]="app.getUserAvatar(membership.userId)"
                    (ionError)="app.fallbackUserAvatar($event?.target)"
                  ></ion-img>
                </ion-avatar>
                {{ membership.name }}
              </ion-item>
            </div>
          </ng-container>
        </ion-list>
        <!-- TEAMS -->
        <ion-list *ngIf="tc.get('teams')?.length">
          <ion-list-header>
            <ion-label color="primary">{{ 'MENU.TEAMS' | translate }}</ion-label>
          </ion-list-header>
          <ion-item
            lines="none"
            *ngFor="let team of getFilteredTeams()"
            [class.tappable]="isCurrentTeam(team.teamId) ? null : true"
            [class.current]="isCurrentTeam(team.teamId)"
            [title]="
              isCurrentTeam(team.teamId) ? t._('MENU.CURRENT_TEAM') : t._('MENU.SELECT_TEAM_X', { team: team.name })
            "
            (click)="selectTeam(team.teamId)"
          >
            <ion-icon
              slot="start"
              color="medium"
              [name]="isCurrentTeam(team.teamId) ? 'radio-button-on' : 'radio-button-off'"
            ></ion-icon>
            {{ team.name }}
            <ion-button
              slot="end"
              fill="clear"
              size="small"
              color="medium"
              *ngIf="!tc.get('membership').permissions.useBadgeMode"
              [title]="t._('MENU.TEAM_SETTINGS', { team: team.name })"
              (click)="manageTeam(team.teamId, $event)"
            >
              <ion-icon name="settings-outline" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
