import { Component, NgZone } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import {
  IDEAAWSAPIService,
  IDEALoadingService,
  IDEAMessageService,
  IDEATinCanService,
  IDEATranslationsService
} from '@idea-ionic/common';

import { AppService } from './app.service';

import { environment as env } from '@env';
import { Membership, MembershipSummary } from '@models/membership.model';
import { Team } from '@models/team.model';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  /**
   * Whether to show the quick switch list.
   */
  hideQuickSwitch = false;

  constructor(
    private router: Router,
    private zone: NgZone,
    private menuCtrl: MenuController,
    private loading: IDEALoadingService,
    private message: IDEAMessageService,
    private api: IDEAAWSAPIService,
    public app: AppService,
    public tc: IDEATinCanService,
    public t: IDEATranslationsService
  ) {
    // configure deep linking feature
    App.addListener('appUrlOpen', ({ url }): void => {
      this.zone.run((): void => {
        const slug = new URL(url).pathname;
        if (slug && slug !== '/') {
          this.tc.set('fromDeepLink', true);
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  /**
   * Hide the menu in some pages.
   */
  isPageWithoutMenu(): boolean {
    return !this.router.url || ['echo', 'auth', 'tutorial', 'intro'].some(x => this.router.url.startsWith('/' + x));
  }
  /**
   * Make the navigator to change the page.
   */
  private navigate(path: string[], force?: boolean): void {
    if (force || this.router.url !== path.join('/')) {
      this.router.navigate(path);
      this.menuCtrl.close();
    }
  }
  /**
   * Navigate to the dashboard page.
   */
  home(): void {
    this.navigate(['teams', this.tc.get('membership').teamId, 'dashboard'], true);
  }
  /**
   * Navigate to the user's profile page.
   */
  editProfile(): void {
    this.navigate(['teams', this.tc.get('membership').teamId, 'profile'], true);
  }
  /**
   * Navigate to the tutorial page.
   */
  tutorial(): void {
    this.navigate(['tutorial']);
  }
  /**
   * Navigate to the tracks page.
   */
  tracks(): void {
    this.navigate(['teams', this.tc.get('membership').teamId, 'tracks']);
  }

  /**
   * Act as another user (dashboard & tracks management).
   */
  async actAsUser(user: MembershipSummary | null = null): Promise<void> {
    const teamId = this.tc.get('membership').teamId;
    const loggedInUserId = this.tc.get('membership').userId;
    const currentlyActingAsUserId = this.tc.get('actAsUser')?.userId;
    const startActAsUserId = user?.userId ?? null;

    if (currentlyActingAsUserId && currentlyActingAsUserId === startActAsUserId) return;

    if (startActAsUserId && startActAsUserId !== loggedInUserId) {
      try {
        const membership = new Membership(
          await this.api.getResource(`teams/${teamId}/memberships`, { resourceId: startActAsUserId })
        );
        this.tc.set('actAsUser', membership);
      } catch (error) {
        this.tc.set('actAsUser', null);
      }
    } else this.tc.set('actAsUser', null);

    window.dispatchEvent(new Event('actingAsAnotherUser'));
    this.menuCtrl.close();
  }

  /**
   * Return whether the team is the currently selected one.
   */
  isCurrentTeam(teamId: string): boolean {
    return teamId === this.tc.get('membership').teamId;
  }
  /**
   * Change the currently selected team.
   */
  async selectTeam(teamId: string): Promise<void> {
    if (this.isCurrentTeam(teamId)) return;

    try {
      this.loading.show();
      // request a team change (so that the `currentTeamId` of the user is updated)
      await this.api.patchResource('users', {
        idea: true,
        resourceId: this.tc.get('membership').userId,
        body: { action: 'CHANGE_TEAM', teamId, project: env.idea.project }
      });
      // reload the app so that it takes the new settings and permissions
      this.app.reloadApp();
    } catch (error) {
      this.message.error('TEAM.TEAM_SWITCH_FAILED');
    } finally {
      this.loading.hide();
    }
  }

  /**
   * Open the team configuration page.
   */
  manageTeam(teamId?: string, event?: any): void {
    // stop the event propagation, to avoid the "click" on the main item
    if (event) event.stopPropagation();
    this.navigate(['teams', teamId || this.tc.get('membership').teamId], true);
  }

  isInBadgeMode(): boolean {
    return this.tc.get('membership').permissions.useBadgeMode;
  }

  getFilteredTeams(): Team[] {
    return this.tc.get('teams').filter(t => !this.isInBadgeMode() || t.teamId === this.tc.get('membership').teamId);
  }

  canLogOut(): boolean {
    return (
      !this.tc.get('membership').permissions.useBadgeMode ||
      (this.tc.get('membership').permissions.useBadgeMode && !this.tc.get('actAsUser'))
    );
  }
}
