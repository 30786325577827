import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import {
  IDEACachedResourcesService,
  IDEAStorageService,
  IDEATinCanService,
  IDEATranslationsService
} from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { environment as env } from '@env';

/**
 * The base URLs where the thumbnails are located.
 */
export const THUMBNAILS_BASE_URL = 'https://s3.eu-west-2.amazonaws.com/orazio-media/thumbnails/';
/**
 * The base URLs where the users avatars are located.
 */
export const USERS_AVATARS_BASE_URL = THUMBNAILS_BASE_URL.concat('avatars/');
/**
 * A local fallback URL for the users avatars.
 */
export const USERS_AVATARS_FALLBACK_URL = './assets/imgs/no-avatar.jpg';
/**
 * The base URLs where the thumbnails are located.
 */
export const TEAMS_LOGOS_BASE_URL = THUMBNAILS_BASE_URL.concat('teamsLogos/');
/**
 * A local fallback URL for the logos.
 */
export const TEAMS_LOGOS_FALLBACK_URL = './assets/imgs/no-logo.jpg';

/**
 * Utilities for Horace.
 */
@Injectable({ providedIn: 'root' })
export class AppService {
  initReady = false;
  authReady = false;

  private darkMode: boolean;

  constructor(
    private platform: Platform,
    private alertCtrl: AlertController,
    private storage: IDEAStorageService,
    private tc: IDEATinCanService,
    private crs: IDEACachedResourcesService,
    private t: IDEATranslationsService,
    private auth: IDEAAuthService
  ) {
    this.darkMode = this.respondToColorSchemePreferenceChanges();
  }
  private respondToColorSchemePreferenceChanges(): boolean {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => (this.darkMode = e.matches));
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  /**
   * Whether we should display a UX designed for smaller screens.
   */
  isInMobileMode(): boolean {
    return this.platform.width() < 768;
  }
  /**
   * Whether the current color scheme preference is set to dark.
   */
  isInDarkMode(): boolean {
    return this.darkMode;
  }

  /**
   * Get the icon of the app.
   */
  getIcon(): string {
    return this.isInDarkMode() ? './assets/icons/icon-auth-alt.svg' : 'assets/icons/icon-auth.svg';
  }

  /**
   * Get the URL to a user's avatar in the current team.
   */
  getUserAvatar(userId?: string, refresh?: boolean): string {
    const teamId = this.tc.get('membership').teamId;
    userId = userId ?? this.tc.get('membership').userId;
    return this.crs.getCachedResource(USERS_AVATARS_BASE_URL.concat(teamId, '/', userId, '.png'), refresh);
  }
  /**
   * Load a fallback URL when the user's avatar is missing.
   */
  fallbackUserAvatar(targetImg: any): void {
    if (targetImg && targetImg.src !== USERS_AVATARS_FALLBACK_URL) targetImg.src = USERS_AVATARS_FALLBACK_URL;
  }

  /**
   * Get the URL to the team's logo.
   */
  getTeamLogo(): string {
    const teamId = this.tc.get('membership').teamId;
    return this.crs.getCachedResource(TEAMS_LOGOS_BASE_URL.concat(teamId, '.png'));
  }
  /**
   * Load a fallback URL when the logo is missing.
   */
  fallbackLogo(targetImg: any): void {
    if (targetImg && targetImg.src !== TEAMS_LOGOS_FALLBACK_URL) targetImg.src = TEAMS_LOGOS_FALLBACK_URL;
  }

  /**
   * Reload the app.
   */
  reloadApp(): void {
    window.location.assign('');
  }
  /**
   * Show info about the app.
   */
  async info(): Promise<void> {
    const header = this.t._('COMMON.APP_NAME');
    const message = this.t._('COMMON.VERSION', { v: env.idea.app.version });
    const buttons = [
      {
        text: this.t._('IDEA_AUTH.PRIVACY_POLICY'),
        handler: () => Browser.open({ url: this.t._('IDEA_VARIABLES.PRIVACY_POLICY_URL') })
      },
      {
        text: this.t._('IDEA_AUTH.TERMS_AND_CONDITIONS'),
        handler: () => Browser.open({ url: this.t._('IDEA_VARIABLES.TERMS_AND_CONDITIONS_URL') })
      },
      { text: this.t._('COMMON.CLOSE') }
    ];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }
  /**
   * Sign-out and clear the local storage.
   */
  async logout(): Promise<void> {
    const header = this.t._('COMMON.LOGOUT');
    const message = this.t._('COMMON.ARE_YOU_SURE');
    const buttons = [
      { text: this.t._('COMMON.CANCEL'), role: 'cancel' },
      {
        text: this.t._('COMMON.LOGOUT'),
        handler: () => this.auth.logout().finally(() => this.storage.clear().then(() => window.location.assign('')))
      }
    ];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }

  /**
   * Chech a permission on the membership of the currently selected team.
   */
  userHasPermission(permissionName: string): boolean {
    return this.tc.get('membership').permissions[permissionName];
  }
}
